.calendar {
  width: 100%;
  max-width: 350px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;

    h2 {
      font-size: 1.2rem;
      font-weight: bold;
      text-transform: capitalize;
    }

    button {
      background: none;
      border: none;
      cursor: pointer;
      transition: transform 0.2s;

      &:hover {
        transform: scale(1.1);
      }
    }

    .icon {
      width: 24px;
      height: 24px;
      color: #333;
    }
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 5px;
  }

  .dayName {
    font-weight: bold;
    color: #555;
    padding: 5px 0;
  }

  .day {
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50%;
    font-size: 1rem;
    cursor: pointer;
    transition: background 0.2s;

    &:hover {
      background: #f0f0f0;
    }
  }

  .currentMonth {
    color: black;
  }

  .otherMonth {
    color: #ccc;
  }

  .selected {
    background: #007bff;
    color: white;
  }
}
