input[type="text"] {
  color: black;
  border-radius: 10px;
  height: 40px;
  background-color: white;
  border: 1px solid black;
  &::placeholder {
    color: grey;
    font-size: 1.4rem;
    @media screen and (max-width: 800px) {
      font-size: 1rem;
    }
  }
}

.previewImg {
  max-width: 200px;
  max-height: 200px;
  z-index: 2;
  @media screen and (max-width: 800px) {
    max-width: 100px;
    max-height: 100px;
  }
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.dropImage {
  height: 90px;
  border-radius: "10px";
  color: white;
  @media screen and (max-width: 800px) {
    padding: 10px;
    height: 120px;
    font-size: 1.3rem;
  }
}

.modeImg {
  height: 300px;
  max-width: 100%;
  @media screen and (max-width: 800px) {
    height: 250px;
    max-width: 100%;
  }
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

input[type="email"] {
  color: black;
  border-radius: 10px;
  height: 40px;
  background-color: white;
  border: 1px solid black;

  &::placeholder {
    color: grey;
    font-size: 1.4rem;
    @media screen and (max-width: 800px) {
      font-size: 1rem;
    }
  }
}

// .containerCheckbox {
//   display: block;
//   position: relative;
//   padding-left: 35px;
//   margin-bottom: 12px;
//   cursor: pointer;
//   font-size: 22px;
//   -webkit-user-select: none;
//   -moz-user-select: none;
//   -ms-user-select: none;
//   user-select: none;
//   input {
//     position: absolute;
//     opacity: 0;
//     cursor: pointer;
//     height: 0;
//     width: 0;
//   }
//   input:checked {
//     .checkmark:after {
//       display: block;
//     }
//   }
//   .checkmark {
//     position: absolute;
//     top: 0;
//     left: 0;
//     height: 25px;
//     width: 25px;
//     background-color: #eee;
//   }

//   .checkmark:after {
//     content: "";
//     position: absolute;
//     display: none;
//   }

//   .checkmark:after {
//     left: 9px;
//     top: 5px;
//     width: 5px;
//     height: 10px;
//     border: solid white;
//     border-width: 0 3px 3px 0;
//     -webkit-transform: rotate(45deg);
//     -ms-transform: rotate(45deg);
//     transform: rotate(45deg);
//   }
// }

// .containerCheckbox:hover {
//   .checkmark {
//     background-color: black;
//   }
// }

.containerCheckbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 30px;
  //   padding-left: 35px;
  //   margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  //   border: 1px solid red;
}

/* Hide the browser's default checkbox */
.containerCheckbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;

  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;

  top: 0;
  left: 10%;
  height: 30px;
  width: 30px;
  border-radius: 10px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.containerCheckbox:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.containerCheckbox input:checked ~ .checkmark {
  background-color: black;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.containerCheckbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.containerCheckbox .checkmark:after {
  left: 10px;
  top: 3px;
  width: 10px;
  height: 20px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.link {
  a,
  a:link,
  a:visited,
  a:hover {
    color: black !important;
    cursor: pointer;
    text-decoration: underline;
  }
}

.cardContainer {
  height: 280px;
  width: 100%;
  .card {
    // border: 1px solid black;
    height: 204px;
    width: 324px;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    img {
      position: absolute;
      height: 100%;
      width: 100%;
      object-fit: cover;
      z-index: 1;
    }
    .nfcIcon {
      z-index: 2;
      position: absolute;
      top: 10px;
      right: 10px;
    }
    .qrCode {
      z-index: 2;
      cursor: grab;
      position: absolute;

      &:active {
        cursor: grabbing;
      }

      img {
        height: 80%;
        width: 80%;
        object-fit: contain;
      }
    }
    .namesurname {
      z-index: 2;
      cursor: grab;
      position: absolute;
      user-select: none;

      &:active {
        cursor: grabbing;
      }
    }
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.8);
  }
}

.materialBtn {
  height: 40px;
  border: 3px solid black;
  border-radius: 20px;
  cursor: pointer;
  font-size: 1.6rem !important;
}
.materialBtnActive {
  height: 40px;
  border-radius: 20px;
  color: white;
  background-color: black;
}

.colorBtn {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 1px solid black;
  overflow: hidden;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    // opacity: 0.7;
  }
}
.colorBtnActive {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 3px solid black;
  overflow: hidden;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    opacity: 1;
  }
}

.cardContainerLogo {
  height: 300px;
  width: 100%;
  .cardLogo {
    // border: 1px solid black;
    height: 220px;
    width: 360px;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    img {
      position: absolute;
      height: 100%;
      width: 100%;
      object-fit: cover;
      z-index: 1;
    }

    .logo {
      z-index: 2;

      img {
        height: 100%;
        width: 1000%;
        object-fit: contain;
      }
    }

    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.8);
  }
}
.textarea {
  height: 100px;
  border: 1px solid black;
  border-radius: 20px;
  color: black;
  &::placeholder {
    color: grey;
    font-size: 1.4rem;
    @media screen and (max-width: 800px) {
      font-size: 1rem;
    }
  }
}
