.filled {
  height: 30px;
  border-radius: 10px;
  width: 40px;
  background-color: black;
  color: white;
}

.empty {
  height: 30px;
  border-radius: 10px;
  width: 40px;
  color: black;
  background-color: white;
  border: 1px solid black;
}
